
















































import { Vue, Provide, Component } from "vue-property-decorator";
import { Toast } from "vant";
import area from "@/utils/area";
import Nav from "@/components/header.vue";
import serve from "@/serve/mine";
interface File {
  status: string;
  message: string;
}
@Component({
  components: {
    Nav,
  },
})
export default class ChangePhone extends Vue {
  @Provide() mobile = "";
  @Provide() code = "";
  @Provide() sendLoading = false;
  @Provide() time = 60;
  @Provide() password = "";
  @Provide() newpassword = "";
  @Provide() show = false;
  @Provide() areaList = area; // 省市区数据
  @Provide() address = ""; // 区域
  @Provide() name = ""; // 公司名称
  @Provide() QQ = ""; // qq  agree
  @Provide() fileList = []; // 营业执照
  @Provide() agree = false; // 同意协议

  async sendSms() {
    if (this.mobile) {
      const reg = /^1[3-9]\d{9}$/;
      if (reg.test(this.mobile)) {
        const res = await serve.getCode(this.mobile);
        if (res.data.code === 0) {
          this.show = true;
          this.sendLoading = true;
          this.cutTime();
        }
      } else {
        Toast("手机号格式错误");
      }
    } else {
      Toast("请输入手机号");
    }
  }

  private phoneValidator(val: string): boolean {
    return /^1[3-9]\d{9}$/.test(val);
  }
  private validator(val: string): boolean {
    // 验证密码
    return val.length > 5 && val.length < 16;
  }
  async submit() {
    const res = await serve.changePhone({
      code: this.code,
      phone: this.mobile,
      password: this.password,
    });
    if(res.data.result === 1){
      Toast.success('修改成功')
      this.$router.push('/mine')
    }
  }
  private showPopup(): void {
    this.show = true;
  }

  private signInSubmit(): void {
    // this.$router.push("/signInSuccess");
    this.show = false;
  }

  private goBack(): void {
    this.$router.push("/login");
  }
  private define(val: object): void {
    this.address = val[0].name + val[1].name + val[2].name;
    this.show = false;
  }
  private cutTime(): void {
    const timer = setInterval(() => {
      this.time--;
      if (this.time == 0) {
        clearInterval(timer);
        this.time = 60;
        this.sendLoading = false;
      }
    }, 1000);
  }

  private afterRead(file: File): void {
    file.status = "uploading";
    file.message = "上传中...";

    setTimeout(() => {
      file.status = "failed";
      file.message = "上传失败";
    }, 1000);
  }
}
